import React from 'react';
import { Container } from 'react-bootstrap';

const VokMusic = () => {
  const spotifyPlaylist = (
    <div className="spotify-playlist">
      <iframe
        src="https://open.spotify.com/embed/track/7Ee6XgP8EHKDhTMYLIndu9?utm_source=generator"
        width="100%"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );

  return (
    <div className="music-page">
      {/* Hero Section */}
      <div className="hero-section-music">
        <div className="hero-overlay"></div>
        <h1>WELCOME TO VOKIM MUSIC</h1>
        <p className="hero-subtitle-music">
          VOKIM is a gospel band dedicated to spreading the gospel through heartfelt worship and praise,
          responding to God's movement across the earth in these transformative times.
        </p>
      </div>

      {/* Albums Container */}
      <div className="albums-container">
        <div className="albums-grid">
          {/* Album 1 */}
          <div className="album-card">
            <div className="card-image-container-music">
              <img
                src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
                alt="AMOPE WA Album"
              />
            </div>
            <div className="card-content">
              <span className="category">Upcoming Album</span>
              <h3 className="title">AMOPE WA – We Offer PRAISE!</h3>
              <p className="year">2025</p>
              <p className="description">
                The Album "Master of the Universe (Olori Aye)" celebrates God's supreme authority and majesty.
                "Olori Aye," which translates to "Ruler of the Earth" in Yoruba, reflects themes of worship,
                reverence, and awe for God's omnipotence. The lyrics highlight God's power to make mountains 
                crumble, defeat enemies, and command the universe, affirming His role as the ultimate creator 
                and sustainer. The song seeks to draw listeners closer to God's presence, emphasizing His 
                unparalleled greatness and ability to provide peace and victory. 
              </p>
              <p className="description">
              The song seeks to draw listeners closer to God's presence, emphasizing His unparalleled greatness and ability to provide peace and victory.
              </p>
              <p className="quote">
                "But in these last days He has spoken to us by His Son, whom He appointed heir of all things,
                and through whom He made the universe."
              </p>
              <p className="quote-source">—Hebrew 1:2</p>
            </div>
            <div className="card-player">
              {spotifyPlaylist}
            </div>
          </div>

          {/* Album 2 */}
          <div className="album-card">
            <div className="card-image-container-music">
              <img
                src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
                alt="MASTER OF THE UNIVERSE Album"
              />
            </div>
            <div className="card-content">
              <span className="category">Upcoming Album</span>
              <h3 className="title">MASTER OF THE UNIVERSE – OLORI AYE</h3>
              <p className="year">2025</p>
              <p className="description">
              This album announces God's focus on thanksgiving - Praising God for His grace and the countless blessings in life. The title roughly translates to "Our Gratitude," emphasizing giving thanks to God. The lyrics reflect themes of thanksgiving, divine grace, and redemption, acknowledging how God's mercy and intervention have transformed lives. It's a song of deep reflection and appreciation for God's unfailing love and support during challenging times.
              </p>
              <p className="quote">
              In the song, reflecting testimonies of overcoming challenges, attributing all victories to God's grace rather than personal effort. "Amope wa" serves as a repeated call to gratitude and worship.
              </p>
              <p className="quote-source">— Ephesians 5:19-20</p>
            </div>
            <div className="card-player">
              {spotifyPlaylist}
            </div>
          </div>

          {/* Album 3 */}
          <div className="album-card">
            <div className="card-image-container-music">
              <img
                src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
                alt="Testimony Overflow Album"
              />
            </div>
            <div className="card-content">
              <span className="category">Upcoming Album</span>
              <h3 className="title">All the Way Yahweh</h3>
              <p className="year">2025</p>
              <p className="description">
              All the Way Yahweh" is a heartfelt journey of faith, worship, and gratitude, celebrating the unfailing goodness and kindness of God. This album invites listeners to reflect on their personal experiences with divine guidance and to rejoice in the unwavering presence of Yahweh through life’s triumphs and trials.
              </p>
              <p className="description">
              Whether you’re standing at a crossroads, celebrating a victory, or seeking solace in challenging times, "All the Way Yahweh" reminds you that God’s kindness and faithfulness endure through every step of the journey. This album is more than music—it’s an experience of worship, designed to inspire and strengthen your faith in the One who is always good and kind.
              </p>
              <p className="quote">
              Prepare to sing, reflect, and celebrate as you join this moving celebration of Yahweh’s love.
              </p>
              <p className="quote-source">—Psalm 25:10</p>
            </div>
            <div className="card-player">
              {spotifyPlaylist}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VokMusic;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MainContent = ({ imageUrl }) => {
  const scrollToNextSection = () => {
    const nextSection = document.getElementById('next-section');
    if (nextSection) {
      const offset = 100;
      const position = nextSection.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="home-page">
      <div className="hero-section-home" style={{ '--bg-image': `url(${imageUrl})` }}>
        <div className="hero-overlay-home"></div>
        <div className="hero-content">
          <div className="content-wrapper-home">
            <h1 className="main-title">RAISING VOICES</h1>
            <h1 className="main-title">EXPANDING HIS KINGDOM: EXALTING JESUS</h1>
            <p className="hero-text">
              VOKIM is a gospel band dedicated to spreading the gospel through heartfelt worship 
              and praise, responding to God's movement across the earth in these transformative times.
            </p>
          </div>
          
        </div>
        <FontAwesomeIcon
            icon={faChevronDown}
            className="scroll-indicator"
            onClick={scrollToNextSection}
          />
      </div>
    </div>
  );
};

export default MainContent;